const IntroText = () => {
    return(
        <>
            <div className="simple-flex fit-content">
                <h1 className="los">Los</h1><h1 className="intro-title">Mejores Sabores</h1>
            </div>
            <div className="simple-flex fit-content">
                <h1 className="los">los</h1><h1 className="intro-title">Tenemos</h1>
            </div>
            <h1 className="intro-title">Aqui En <strong>Casa</strong></h1>
        </>
    )
}

export default IntroText