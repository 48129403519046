const images = {
    instagram: require('./icons/instagram-black.webp'),
    instagramNaranja: require('./icons/instagram.webp'),
    whatsapp: require('./icons/whatsapp-black.webp'),
    whatsappNaranja: require('./icons/whatsapp.webp'),
    check: require('./icons/check-gif.gif'),
    logoNegro: require('./icons/casa-logo.webp'),
    logoNaranja: require('./icons/casa-logo-naranja.webp'),
    left: require('./icons/left-arrow.webp'),
    right: require('./icons/right-arrow.webp'),
    almendra: require('./icons/almendra-naranja.webp'),
    almendraNaranja: require('./icons/avellana-naranja.webp'),
    avellana: require('./icons/avellana.webp'),
    avellanaNaranja: require('./icons/avellana-naranja.webp'),
    badge: require('./icons/badge.webp'),
    butter: require('./icons/butter.webp'),
    close: require('./icons/close.webp'),
    bolsa: require('./icons/shopping-bag.webp'),
    bolsaNaranja: require('./icons/shopping-bag-orange.webp'),
    bg1: require('./img/condimentos-bg-1.webp'),
    bg2: require('./img/condimentos-bg-2.webp'),
    bg3: require('./img/condimentos-bg-3.webp'),
    bg4: require('./img/condimentos-bg-4.webp'),
    dulces: require('./icons/dulces.webp'),
    email: require('./icons/email.webp'),
    condimentosbadge3: require('./img/condimentos-rueda.webp'),
    quotes: require('./icons/quotation-mark.webp'),
    bakery: require('./icons/magdalena.webp'),
    menu: require('./icons/menu-black.webp'),
    menuNaranja: require('./icons/menu-naranja.webp'),
    nueces: require('./icons/nueces.webp'),
    pickles: require('./icons/pickles.webp'),
    pistachos: require('./icons/pistacho.webp'),
    pistachosNaranja: require('./icons/pistacho-naranja.webp'),
    search: require('./icons/search-black.webp'),
    searchNaranja: require('./icons/search.webp'),
    send: require('./icons/send-message.webp'),
    mayordetal: require('./icons/wholesale.webp'),
    oregano: require('./img/IMG_0024.webp'),
    pimientaNegra: require('./img/IMG_0250.webp'),
    pimientaMolida: require('./img/IMG_0444.webp'),
    delivery: require('./img/IMG_0026.webp'),
    cebolla: require('./img/IMG_0213.webp'),
    canelaMolida: require('./img/IMG_0474.webp'),
    canelaRama: require('./img/IMG_0496.webp'),
    comino: require('./img/IMG_9978.webp'),
    cuatrocondimentos: require('./img/IMG_0739.webp'),
    cuatrocondimentos1: require('./img/IMG_0740.webp'),
    cuatrocondimentos2: require('./img/IMG_1813.webp'),
    platopimienta2: require('./img/IMG_0741.webp'),
    platopimienta4: require('./img/IMG_0743.webp'),
    platopimienta4_2: require('./img/IMG_1812.webp'),
    platopimienta4_3: require('./img/IMG_8190.webp'),
    cominoplato2: require('./img/IMG_1101.webp'),
    cominoplato2_1: require('./img/IMG_4890.webp'),
    paprikacomino3: require('./img/IMG_1674.webp'),
    paprikacomino3_2: require('./img/IMG_1815.webp'),
    muchoscondimentos: require('./img/IMG_1811.webp'),
    muchoscondimentos2: require('./img/IMG_2967.webp'),
    muchoscondimentos3: require('./img/IMG_8130.webp'),
    frutosecos1: require('./img/IMG_6999.webp'),
    frutosecos2: require('./img/IMG_8501.webp'),
    frutosecos3: require('./img/IMG_8662.webp'),
    pin: require('./icons/pin.webp'),
    underline: require('./icons/Line.webp'),
    condimentoIcon: require('./icons/condimento.webp'),
    chemical: require('./icons/test.webp'),
    rice: require('./icons/rice.webp'),
    moto: require('./icons/food-delivery.webp'),
}

export default images